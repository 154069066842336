<template lang="pug">
	footer#mainFooter(v-if="$configuracoes.configuracoes")
		div.acesse
			.wrapper
				div.associe
					h4 {{$configuracoes.configuracoes.footer.nome}}
					p(v-html='$configuracoes.configuracoes.footer.conteudo')

					router-link(:to="{name: 'associado'}") {{$configuracoes.configuracoes.footer.texto_botao}}
				div.site
					img(:src="$configuracoes.configuracoes.footer.media")
					a(:href="$configuracoes.configuracoes.footer.link") Acesse o site da ANCP
		div.reservado
			.wrapper
				span &copy; {{ ano }} - Todos os direitos reservados - ANCP - CNPJ: {{ cnpj }}
</template>

<script>
export default {
	computed: {
		ano() {
			return new Date().getFullYear()
		},
		cnpj() {
			const cnpj = this.$configuracoes.configuracoes.cnpj
			return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12)}`
		}
	},
};
</script>

<style lang="stylus" scoped src="./Footer.styl"></style>
