import Vue from 'vue'
import Router from 'vue-router'
import '@lib/prototypes';

const vm = new Vue();
Vue.use(Router)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import( /* webpackChunkName: "home" */ '@views/home/Home.vue')
	},
	{
		path: '/politicadeprivacidade',
		name: 'politicadeprivacidade',
		component: () => import( /* webpackChunkName: "politicadeprivacidade" */ '@views/politicadeprivacidade/PoliticaDePrivacidade.vue')
	},
	{
		path: '/contato',
		name: 'contato',
		component: () => import( /* webpackChunkName: "contato" */ '@views/contato/Contato.vue')
	},
	{
		path: '/termo-de-uso',
		name: 'termoDeUso',
		component: () => import( /* webpackChunkName: "termodeuso" */ '@views/termosdeuso/TermoDeUso.vue')
	},
	{
		path: '/eventos',
		name: 'eventos',
		component: () => import( /* webpackChunkName: "eventos" */ '@views/eventos/Eventos.vue')
	},
	{
		path: '/eventos/:slug',
		name: 'evento',
		component: () => import( /* webpackChunkName: "evento" */ '@views/evento/Evento.vue')		
	},
	{
		path: '/eventos/:slug/local',
		name: 'local',
		component: () => import( /* webpackChunkName: "local" */ '@views/local/Local.vue')
	},
	{
		path: '/esqueci-minha-senha',
		name: 'esqueceuSenha',
		component: () => import( /* webpackChunkName: "EsqueceuSenha" */ '@views/esqueceuSenha/EsqueceuSenha.vue'),
		beforeEnter: (to, from, next) => {
			if (vm.$auth.isUsuarioAuthenticated())
				return next('/')
			return next()
		},
	},
	{
		path: '/nova-senha',
		name: 'novaSenha',
		component: () => import( /* webpackChunkName: "novaSenha" */ '@views/novaSenha/NovaSenha.vue'),
		beforeEnter: (to, from, next) => {
			if (!to.query.token || vm.$auth.isUsuarioAuthenticated())
				return next('/');
			return next();
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "Login" */ '@views/login/Login.vue'),
		beforeEnter: (to, from, next) => {
			if (vm.$auth.isUsuarioAuthenticated())
				return next('/')
			return next()
		},
	},
	{
		path: '/registre',
		name: 'registre',
		component: () => import( /* webpackChunkName: "Registrar" */ '@views/registrar/Registrar.vue'),
		beforeEnter: (to, from, next) => {
			if (vm.$auth.isUsuarioAuthenticated())
				return next('/')
			return next()
		},
	},
	{
		path: '/meu-perfil',
		name: 'meuPerfil',
		component: () => import( /* webpackChunkName: "MeuPerfil" */ '@views/meuPerfil/MeuPerfil.vue')
	},
	{
		path: '/eventos/:slug/parceiros',
		name: 'expositores',
		component: () => import( /* webpackChunkName: "Expositores" */ '@views/expositores/Expositores.vue')
	},
	{
		path: '/eventos/:slug/ingressos',
		name: 'ingressos',
		component: () => import( /* webpackChunkName: "Ingressos" */ '@views/ingressos/Ingressos.vue')
	},
	{
		path: '/eventos/:slug/palestrantes',
		name: 'palestrantes',
		component: () => import( /* webpackChunkName: "Palestrantes" */ '@views/palestrantes/Palestrantes.vue')
	},
	{
		path: '/carrinho',
		name: 'carrinho',
		component: () => import( /* webpackChunkName: "Carrinho" */ '@views/carrinho/Carrinho.vue')
	},
	{
		path: '/associado',
		name: 'associado',
		component: () => import( /* webpackChunkName: "Associado" */ '@views/associado/Associado.vue')
	},
	{
		path: '/checkout',
		name: 'finalizeCompra',
		component: () => import( /* webpackChunkName: "finalizeCompra" */ '@views/finalizeCompra/FinalizeCompra.vue'),
		beforeEnter: (to, from, next) => {
			let redirectTo = to.fullPath.slice(1)
			window.localStorage.setItem('ancp-attemptedRoute', redirectTo)
			vm.$axios.get(`api/usuario`)
				.then(() => {
					if (!vm.$carrinho.carrinho)
						return next({ name: 'home' })
					return next()
				}).catch(() => false)
		}
	}
];

routes.push({
	path: '/*',
	name: '404',
	component: () => import( /* webpackChunkName: "error404" */ '@views/error404/Error404.vue')
})

let router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {

	if (to.path !== '/meu-perfil' && vm.$auth.isUsuarioAuthenticated() && !vm.$auth.usuario.conta_atualizada) {
        alert("Você precisa atualizar seu perfil para poder usar o sistema");
		return next('/meu-perfil');
	}

	// navigate to to.path() page
	next();
})

export default router;
