import Vue from 'vue'
import Axios from 'axios'
import Auth from '@lib/auth'
import Carrinho from '@lib/carrinho'
import Configuracoes from '@lib/configuracoes'
import router from '@/router'

let axiosConfig = {
	baseURL: `${process.env.VUE_APP_API_ROUTE}`,
    crossDomain: true,
    xhrFields: { withCredentials: true }
}

Vue.prototype.$eventsHandle = new Vue();
Vue.prototype.$axios = Axios.create(axiosConfig);
Vue.prototype.$configuracoes = Configuracoes();
Vue.prototype.$carrinho = Carrinho();
Vue.prototype.$auth = Auth();

Vue.prototype.$axios.interceptors.response.use(config => config, error => {
	if (error.response && error.response.status == 401) {
		if(localStorage.getItem('ancp-attemptedRoute') != 'checkout'){
			Vue.prototype.$auth.usuario = null;
			Vue.prototype.$auth.__removeLocal();
			Vue.prototype.$carrinho.__removeLocal();
		}
		router.replace({ 
			name: 'login', 
			query: { 
				redirectTo: window.localStorage.getItem('ancp-attemptedRoute') 
			} 
		});
	}
	return Promise.reject(error);
});