import Vue from 'vue';

const _localName = 'ancp-auth';

export default () => {
	return new Vue({
		name: 'Auth',
		data() {
			return {
				usuario: null,
				errors: null,
				formLogin: {
					email: '',
					password: '',
					carrinho_id: null
				}
			}
		},
		created() {
			this.usuario = this.__getLocal();
			if (!this.isUsuarioAuthenticated()) {
				this.usuario = null;
				this.__removeLocal();
			}
			this.$eventsHandle.$on('carrinhoInvalido', () => {
				if (this.usuario)
					this.usuario.carrinho_id = null;
			})
			this.$eventsHandle.$on('carrinhoValido', carrinho_id => {
				if (this.usuario)
					this.usuario.carrinho_id = carrinho_id;
			})
		},
		watch: {
			usuario: {
				handler: function () {
					if (this.usuario)
						this.__updateLocal(this.usuario.token)
				},
				deep: true
			}
		},
		methods: {
			login($callback = null) {
				this.errors = null;
				this.formLogin.carrinho_id = this.$carrinho.carrinho ? this.$carrinho.carrinho.id : null;
				return this.$axios
				.post(`api/login`, this.formLogin)
				.then(response => {
					this.usuario = response.data;
					this.formLogin = {
						email: '',
						password: ''
					};
					this.__updateLocal(response.data.token);
					if (this.usuario.carrinho_id)
						this.$carrinho.loadCarrinho(this.usuario.carrinho_id);
					return true;
				})
				.catch(error => {
					if (error.response.status == 422)
						this.errors = error.response.data.errors
					this.__removeLocal();
					return false;
				})
				.finally(() => {
					if ($callback && typeof $callback == 'function')
						$callback();
				})
			},
			logout($callback = null) {
				this.$axios
				.get(`api/logout`)
				.then(() => true)
				.catch(() => false)
				.finally(() => {
					this.usuario = null;
					this.__removeLocal();
					this.$carrinho.__removeLocal();
					if ($callback && typeof $callback == 'function')
						$callback();
				})
			},
			isUsuarioExpired() {
				if (!this.usuario)
					return true;
				if (new Date(this.usuario.expires) < new Date())
					return true;
				return false;
			},
			isUsuarioAuthenticated() {
				if (!this.usuario)
					return false;
				if (this.isUsuarioExpired())
					return false;
				return true;
			},
			usuarioHasAssociacao(slug = null) {
				if (this.isUsuarioAuthenticated()) {
					if(slug === null){
						if (this.usuario.all_associacoes && this.usuario.all_associacoes.length > 0)
							return true;
					}else{
						let associacao = this.usuario.all_associacoes.find(associacao => associacao.slug === slug);
						if(associacao && associacao.slug === slug)
							return true;
					}
				}
				return false;
			},
			isUsuarioAssociado(slug = null) {
				if (this.isUsuarioAuthenticated()) {
					if (slug === null){
						if (this.usuario.associado)
							return true;
					}else{
						let associacao = this.usuario.associacoes.find(associacao => associacao === slug);
						if(associacao && associacao === slug)
							return true;
					}
				}
				return false;
			},
			__updateLocal(token) {
				window.localStorage.setItem(_localName, JSON.stringify(this.usuario));
				this.$axios.defaults.headers.authorization = `Bearer ${token}`;
			},
			__removeLocal() {
				window.localStorage.removeItem(_localName);
				delete this.$axios.defaults.headers.authorization;
			},
			__getLocal() {
				let usuario = window.localStorage.getItem(_localName)
				if (usuario) {
					let user = JSON.parse(usuario);
					this.$axios.defaults.headers.authorization = `Bearer ${user.token}`;
					if (user.carrinho_id)
						this.$eventsHandle.$emit('carrinho_id_usuario', user.carrinho_id);
					return user;
				}
				return usuario
			},
		},
	});
}