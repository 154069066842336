<template lang="pug">
	header#mainHeader
		div.logo
			router-link(:to="{name: 'home'}")
				img(:src="require('@images/logo/logoancplonga.png')")
		div.menu(@click="toggleMenu()")
			img(:src="require('@images/icones/menu.png')")
		nav(ref="nav")
			ul
				li(@click="toggleMenu()")
					router-link(:to="{name: 'eventos'}") Eventos
				li(@click="toggleMenu()")
					router-link(:to="{name: 'associado'}") Registre-se
				li(@click="toggleMenu()")
					a(:href="$site_url + 'contato'", target="_blank") Contato
				li(@click="toggleMenu()")
					a(:href="`${$site_url}`", target="_blank") Site ANCP
		div.acessar(ref="acessar")
			ul
				li(@click="toggleMenu()").carrinho
					router-link(:to="{name: 'carrinho'}")
						img(:src="require('@images/icones/cart.png')")
						span(v-if="$carrinho.totalProdutos > 0") {{$carrinho.totalProdutos}}

				li(v-if="!$auth.isUsuarioAuthenticated()", @click="toggleMenu()").registrar
					router-link(:to="{name: 'registre'}") Registre-se

				li(v-if="!$auth.isUsuarioAuthenticated()", @click="toggleMenu()").login
					button(@click="$eventsHandle.$emit('toggleFormLogin')") Login

				li(v-if="$auth.isUsuarioAuthenticated()", @click="toggleMenu()").registrar
					router-link(:to="{name: 'meuPerfil'}") Meu Perfil

				li(v-if="$auth.isUsuarioAuthenticated()", @click="toggleMenu()").logout
					button(@click="$auth.logout(doRedirect)") Logout

</template>

<script>
export default {
	methods: {
		toggleMenu() {
			this.$refs.nav.classList.toggle('ativo');
			this.$refs.acessar.classList.toggle('ativo');
		},
		fecharMenu() {
			if(this.$refs.nav.classList.contains('ativo')){
				this.$refs.nav.classList.remove('ativo');
				this.$refs.acessar.classList.remove('ativo');
			}
		},
		doRedirect() {
			if (this.$route.name != 'home')
				this.$router.push('/')
		}
	},
}
</script>

<style lang="stylus" scoped src="./Header.styl"></style>
