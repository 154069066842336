<template lang="pug">
	div#login(@click.self="$eventsHandle.$emit('toggleFormLogin', false)")
		div.acesso(v-if="ativo")
			h5 Login
			p Faça seu login e tenha acesso a área restrita dos associados.
			form(v-on:submit.prevent="sendLogin", autocomplete="off")
				.input(:class="{error: $auth.errors && $auth.errors.email }")
					input(placeholder="E-mail", name="email", v-model="$auth.formLogin.email")
					span(v-if="$auth.errors && $auth.errors.email").error {{ $auth.errors.email[0] }}
				.input(:class="{error: $auth.errors && $auth.errors.password }")
					input(type="password", placeholder="Senha", name="senha", v-model="$auth.formLogin.password")
					span(v-if="$auth.errors && $auth.errors.password").error {{ $auth.errors.password[0] }}
				div.button
					button(:disabled="sending") {{ textBotao }}
			div.toggle
				button(@click="toggleLogin") Esqueceu a senha?

		div.senha(v-else)
			h5 Esqueceu a senha?
			p Enviaremos um link de recuperação de senha para o seu email.
			form(v-on:submit.prevent="sendRecuperar", autocomplete="off")
				//- div.input
				//- 	input(placeholder="Nome", name="nome", v-model="validar.nome")
				div.input(:class="{error: errors.email }")
					input(placeholder="E-mail", name="email", v-model="validar.email")
					span(v-if="errors.email") {{errors.email[0]}}
				div.button
					button(:disabled="sending") {{ textBotao }}
			div.toggle
				button(@click="toggleLogin") Login
</template>

<script>
export default {
	data() {
		return {
			login: {},
			validar: {
				email: ""
			},
			errors: {},
			ativo: true,
			sending: false,
			textBotao: "Entrar"
		};
	},
	methods: {
		toggleLogin() {
			this.errors = {};
			this.sending = false;
			this.ativo = !this.ativo;
			if (this.ativo)
				this.textBotao = "Enviar";
			else
				this.textBotao = "Validar";
		},
		sendLogin() {
			this.sending = true;
			this.textBotao = "Entrando"
			this.$auth.login()
				.then(response => {
					this.sending = false;
					if (response) {
						this.$eventsHandle.$emit('toggleFormLogin', false)
					}
					this.textBotao = "Entrar";
				});
		},
		sendRecuperar() {
			this.textBotao = "Enviando"
			this.message = null;
			this.errors = {};
			this.sending = true;
			this.$axios
				.post(`api/password/email`, this.validar)
				.then(response => {
					this.textBotao="Enviado!"
					this.validar = {
						email: ''
					}
					this.message = response.data.message;
				})
				.catch(error => {
					this.sending = false;
					this.textBotao = "Validar";
					if (error.response.status < 500)
						this.errors = error.response.data.errors
				})
				.finally(() => {
					setTimeout(() => {
						this.sending = false;
						this.textBotao = "Validar";
					}, 4000)
				})
		}
	}
};
</script>

<style lang="stylus" scoped src="./Login.styl"></style>
