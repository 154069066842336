import Vue from 'vue';

export default () => {
    return new Vue({
        name: 'Configuracoes',
        data() {
            return {
                configuracoes: null
            }
        },
        created() { 
            this.loadConfiguracoes()
        },
        methods: {
            loadConfiguracoes() {
                this.$axios
                    .get(`api/configuracoes`)
                    .then(response => {
                        this.configuracoes = response.data
                    })
            }
        }
    });    
}