import Vue from 'vue';
const _localName = 'ancp-carrinho';

export default () => {
    return new Vue({
        name: 'Carrinho',
        data() {
            return {
                carrinho: null,
                queue: [],
                isQueueIdle: true,
            }
        },
        created() {
            let carrinho = this.__getLocal();
            if (carrinho)
                this.loadCarrinho(carrinho.id);
            this.$eventsHandle.$on('carrinho_id_usuario', this.loadCarrinho)
        },
        computed: {
            produtos() {
                if (this.carrinho && this.carrinho.produtos)
                    return this.carrinho.produtos.map(produto => produto.produto_id)
                return []
            },
            totalProdutos() {
                return this.produtos.length;
            },
            isAnyProdutoEsgotado() {
                if (this.carrinho && this.carrinho.produtos) {
                    let produtosEsgotados = this.carrinho.produtos.filter(produto => produto.produto.esgotado) || []
                    return produtosEsgotados.length ? true : false
                }
                return false
            },
        },
        watch: {
            isQueueIdle() {
                if (this.isQueueIdle && this.queue.length) {
                    this.__processQueue()
                        .then(response => {
                            this.loadCarrinho(response.data.carrinho_id);
                        });
                }
            }
        },
        methods: {
            loadCarrinho(carrinho_id) {
                this.$axios
                    .get(`api/carrinho/${carrinho_id}`)
                    .then(response => {
                        this.carrinho = response.data;
                        this.__updateLocal();
                        this.$eventsHandle.$emit('carrinhoValido', this.carrinho.id)
                        return true;
                    })
                    .catch(error => {
                        if (error.response.status == 422) {
                            this.carrinho = null;
                            this.__removeLocal();
                        }
                        this.$eventsHandle.$emit('carrinhoInvalido')
                        return false;
                    })
            },
            adicionar(produto_id) {
                let produtos = [];
                if (typeof produto_id == 'number') 
                    produtos.push(produto_id);
                else
                    produtos = produto_id;

                produtos.forEach(produto => {
                    this.queue.push(produto);
                })
                
                if (this.isQueueIdle && this.queue.length)
                    this.__processQueue()
                        .then(response => {
                            if(this.isQueueIdle && !this.queue.length)
                                this.loadCarrinho(response.data.carrinho_id);
                        });
            },
            remover(produto_id) {
                let remover = { produto_id };
                if (this.carrinho) 
                    remover.carrinho_id = this.carrinho.id;

                this.$axios
                    .post(`api/carrinho/remover`, remover)
                    .then(response => {
                        this.loadCarrinho(response.data.carrinho_id);
                    })
                    .catch(error => {
                        console.log(error.response) // eslint-disable-line
                    })
            },
            atualizar(produto_id, tipo) {
                let atualizar = { produto_id };
                if (this.carrinho) 
                    atualizar.carrinho_id = this.carrinho.id;

                this.$axios
                    .put(`api/carrinho/${tipo}`, atualizar)
                    // eslint-disable-next-line
                    .then(response => {
                        this.loadCarrinho(this.carrinho.id);
                    })
                    .catch(error => {
                        console.log(error.response) // eslint-disable-line
                    })
            },
            inProdutos(produto_id) {
                return this.produtos.find(produto => produto == produto_id) ? true : false;
            },
            __addProduct(produto_id) {
                let adicionar = { produto_id };
                if (this.carrinho) 
                    adicionar.carrinho_id = this.carrinho.id;

                return this.$axios
                    .post(`api/carrinho/adicionar`, adicionar)
                    .then(response => response)
                    .catch(error => error)
            },
            __updateLocal() {
                window.localStorage.setItem(_localName, JSON.stringify(this.carrinho));
            },
            __removeLocal() {
                this.carrinho = null;
                window.localStorage.removeItem(_localName);
            },
            __getLocal() {
                let carrinho = window.localStorage.getItem(_localName)
                if (carrinho)
                    return JSON.parse(carrinho);
                return carrinho
            },
            __processQueue() {
                this.isQueueIdle = false;
                return this.__addProduct(this.queue.shift())
                    .then(response => {
                        if (this.carrinho)
                            this.carrinho.id = response.data.carrinho_id;
                        else
                            this.carrinho = { id: response.data.carrinho_id }

                        if (this.isQueueIdle && this.queue.length)
                            this.__processQueue()
                                .then(response => response);
                        else {
                            this.isQueueIdle = true;
                            return response;
                        }
                    })
                    .catch(error => error)
            }
        },
    });    
}