import '@stylus/main.styl'

import Vue from 'vue'
import { App } from '@/app'
import router from '@/router'
import '@lib/prototypes';

Vue.config.productionTip = false
Vue.prototype.$site_url = `${process.env.VUE_APP_SITE_URL}/`

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
