<template lang="pug">
	main#app
		Header(ref="header")
		Login(ref="login", :class="{ativo: isFormLoginAtivo}")
		router-view(ref="page")
		Footer
</template>

<script>
import Header from '@components/header/Header.vue'
import Login from '@components/login/Login.vue'
import Footer from '@components/footer/Footer.vue'
export default {
	components: {
		Header,
		Login,
		Footer,
	},
	data() {
		return {
			isFormLoginAtivo: false
		}
	},
	created() {
		this.$eventsHandle.$on('toggleFormLogin', this.toggleFormLogin)
	},
	mounted() {
		this.addListenerFechaMenu();
	},
	methods: {
		toggleFormLogin(option = true) {
			if (option) {
				this.isFormLoginAtivo = true;
				document.querySelector('html').style.overflow = 'hidden'
				document.querySelector('body').style.overflow = 'hidden'
			} else {
				this.isFormLoginAtivo = false;
				document.querySelector('html').style.overflow = 'visible'
				document.querySelector('body').style.overflow = 'visible'
			}
		},
		addListenerFechaMenu(){
			if(this.$refs){
				if(this.$refs.page)
					this.$refs.page.$el.addEventListener('click', this.$refs.header.fecharMenu)
			}
			else{
				setTimeout(() => {
					this.addListenerFechaMenu();
				}, 250);
			}
		}
	},
}
</script>

<style lang="stylus" src="@templates/App/App.styl"></style>
